import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import { Link } from "react-router-dom"
import { Image, message } from "antd"
import axios from "axios"

const FormUpload = props => {
  const [loading, setLoading] = useState(false)
  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "Images", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Images", breadcrumbItems)
  })

  const [selectedFiles, setselectedFiles] = useState({
    sliders: [],
    backgroundImage: "",
  })

  useEffect(() => {
    if (props.isBackground) {
      setselectedFiles(prev => ({
        backgroundImage: `${process.env.REACT_APP_BASE_URL}/assets/image/backgroundImage.png`,
      }))
    } else {
      fetchSlider()
    }
  }, [props])

  // Remove this import: import axios from 'axios';

  async function handleAcceptedFiles(files) {
    setLoading(true)

    try {
      let backgroundImage = props.isBackground ? files[0] : files
      const formData = new FormData()

      if (props.isBackground) {
        formData.append("image", backgroundImage)

        let url = `${process.env.REACT_APP_BASE_URL}/backgroundImage/add`

        try {
          const response = await fetch(url, {
            method: "POST",
            body: formData,
          })

          const data = await response.json()
          setTimeout(() => {
            setLoading(false)
            window.location.reload()
            console.log("Upload completed successfully")
          }, 2000)
          return data
        } catch (error) {
          message.error("Error Uploading Image")
          return null
        }
      } else {
        backgroundImage.map(file => {
          formData.append("image", file)

          uploading(formData)
        })
        setTimeout(() => {
          setLoading(false)

          console.log("Upload completed successfully")
        }, 2000)
      }
    } catch (error) {
      message.error("Error Uploading Image")
    }
  }

  const uploading = async formData => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/slider/add`,
        {
          method: "POST",
          body: formData,
        }
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      fetchSlider()
    } catch (error) {
      message.error("Error Uploading Image")
    }
  }
  const fetchSlider = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/slider`)
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const data = await response.json()

      // Assuming `setselectedFiles` is a state setter function in your React component
      setselectedFiles(prev => ({
        ...prev,
        sliders: data,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async (url, method, body) => {
    try {
      const response = await axios({
        url: url,
        method: method,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      const data = response.data
      return data
    } catch (error) {
      message.error("Error Uploading Image")
      return null
    }
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleDelete = async id => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/slider/${id}`,
        {
          method: "DELETE",
          headers: new Headers({
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
          }),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      message.success("Slider deleted successfully")
      fetchSlider()
    } catch (error) {
      console.error("Error during DELETE request:", error)
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Form>
                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {loading && (
                  <div className="text-center mt-3">
                    <Spinner color="primary" />
                    <p>Uploading...</p>
                  </div>
                )}
                <div className="dropzone-previews mt-3" id="file-previews">
                  {!props.isBackground &&
                    selectedFiles?.sliders?.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={
                                    f.preview ||
                                    `${process.env.REACT_APP_BASE_URL}/assets/${f.Image}`
                                  }
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col>
                                {props.isBackground && (
                                  <Button style={{ margin: "10px" }}>
                                    More Details
                                  </Button>
                                )}

                                <Button
                                  onClick={() => {
                                    handleDelete(f.ID)
                                  }}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}

                  {props.isBackground && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/assets/image/backgroundImage.png`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  )}
                </div>
              </Form>

              <div className="text-center mt-4"></div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(FormUpload)

import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col, Label, Card, CardBody, Button } from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
import EmailSent from "./email-sent"
import MonthlyEarnings2 from "./montly-earnings2"
import Inbox from "./inbox"
import RecentActivity from "./recent-activity"
import WidgetUser from "./widget-user"
import YearlySales from "./yearly-sales"
import LatestTransactions from "./latest-transactions"
import LatestOrders from "./latest-orders"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import DatatableTables from "../Products/table"
import ReactSwitch from "react-switch"
import { hasFormSubmit } from "@testing-library/user-event/dist/utils"
import { set } from "lodash"
const Dashboard2 = props => {
  const breadcrumbItems = [
    { title: "Janajaya.lk", link: "#" },
    { title: "products", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Products", breadcrumbItems)
  })

  const reports = [
    {
      title: "Orders",
      iconClass: "cube-outline",
      total: "1,587",
      average: "+11%",
      badgecolor: "info",
    },
    {
      title: "Revenue",
      iconClass: "buffer",
      total: "$46,782",
      average: "-29%",
      badgecolor: "danger",
    },
    {
      title: "Average Price",
      iconClass: "tag-text-outline",
      total: "$15.9",
      average: "0%",
      badgecolor: "warning",
    },
    {
      title: "Product Sold",
      iconClass: "briefcase-check",
      total: "1890",
      average: "+89%",
      badgecolor: "info",
    },
  ]
  const Offsymbol = props => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const [switch1, setswitch1] = React.useState(false)
  const [maxValue, setmaxValue] = React.useState(0)
  const [_id, set_id] = React.useState(0)
  const [charge, setcharge] = React.useState(0)

  useEffect(() => {
    fetchMaxLimit()
  }, [props])

  const fetchMaxLimit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/getMaxLimit`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      const responseData = await response.json()
      
      if (responseData?.length > 0) {
        setmaxValue(responseData[0].maxLimit)
        setswitch1(responseData[0].hasMaxLimit === 1 ? true : false)
        set_id(responseData[0].idmaxLimit)
        setcharge(responseData[0].charge)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const hasFormSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/updateMaxLimit/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idmaxLimit: _id,
            hasMaxLimit: switch1 ? 1 : 0,
            maxLimit: maxValue,
            charge: charge,
          }),
        }
      )
      const responseData = await response.json()
   
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          tableLayout: "fixed",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row",  justifyContent: "space-between", alignItems: "flex-end", gap: 10, marginBottom: 10 }}>
          <div>
            <div>
              <Label> Has MaxLimit</Label>
            </div>

            <ReactSwitch
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={() => {
                setswitch1(!switch1)
              }}
              checked={switch1}
            />

            <div style={{marginTop: 30}}>
              <Label> MaxLimit</Label>
            </div>

            <input
              type="number"
              className="form-control"
              placeholder="MaxLimit"
              value={maxValue}
              onChange={e => {
                setmaxValue(e.target.value)
              }}
            />
          </div>

          <div>
            <Label> Charge</Label>
            <input
              type="number"
              className="form-control"
              placeholder="Charge"
              value={charge}
              onChange={e => {
                setcharge(e.target.value)
              }}
            />
          </div>
          <div>
            <Button
              color="primary"
              onClick={() => {
                hasFormSubmit()
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>

      <DatatableTables />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard2)
